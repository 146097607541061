import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Background from "../components/Background/Background";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import FAQs from "../components/Repeating/FAQ";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   return (
      <Layout>
         <SearchEngineOptimization
            title="About | Conscious Legal | Estate Planning Law Firm"
            description="At Conscious Legal, we ensure that your plan is fully executed when you and your loved ones need it most. Learn more about us here."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-12 md:py-10">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-x-10 gap-y-10 items-center">
                  <div>
                     <h1>About Us</h1>
                     <p className="mb-0">
                        At Conscious Legal, APC we ensure your plan is going to work. If you want forms and legal documents, please visit a
                        traditional estate planning lawyer. If you prefer a truly personal and concierge experience, then we are the right choice for
                        you.
                     </p>
                  </div>
                  <div className="-mx-4 md:mx-0">
                     <GatsbyImage image={data.heroSplitDesktop.childImageSharp.gatsbyImageData} alt="About Us" />
                  </div>
               </div>
            </div>
         </section>

         <Background
            backgroundImages={data.backgroundDesktop.childImageSharp.gatsbyImageData}
            backgroundPosition="25% 15%"
            padding="pt-18 md:pt-44 pb-10 md:pb-44"
            mobileRemoveBackground={true}
         >
            <div className="grid md:grid-cols-12 md:gap-x-16 lg:gap-x-10 gap-y-16">
               <div className="md:col-end-13 md:col-span-6">
                  <h2>What Makes Our Firm Unique</h2>
                  <p className="mb-0">
                     Conscious Legal does not exist just to create a set of documents you will likely never update or review - we are here to empower
                     you in life and business while ensuring your loved ones have someone to turn to when you are no longer here. We are also here to
                     support you in maintaining a life of prosperity, make your mark on the world, and leave a lasting legacy.
                  </p>
               </div>
            </div>
         </Background>

         <GatsbyImage image={data.backgroundMobile.childImageSharp.gatsbyImageData} alt="What Makes Our Firm Unique" className="md:hidden" />

         <About headingLevel="h2" />

         <Testimonials headingLevel="h2" />

         <FAQs className="pt-20 md:pt-32 pb-16 md:pb-24" headingLevel="h2" />

         <CallToAction headingLevel="h2" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/About Us_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/About Us_TW.jpg" }) {
         publicURL
      }
      heroSplitDesktop: file(relativePath: { eq: "about/1.0 team - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      backgroundDesktop: file(relativePath: { eq: "about/2.0 kid - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      backgroundMobile: file(relativePath: { eq: "about/2.0 kid - mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
