import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Background from "../Background/Background";
import ButtonUnderline from "../Button/ButtonUnderline";

const About = ({ className, headingLevel }) => {
   const data = useStaticQuery(graphql`
      {
         backgroundDesktop: file(relativePath: { eq: "repeating/about/Meet-Alanna_desktop.jpg" }) {
            childImageSharp {
               gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
            }
         }
         backgroundMobile: file(relativePath: { eq: "repeating/about/background-ivy-mobile.jpg" }) {
            childImageSharp {
               gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
            }
         }
         alanna: file(relativePath: { eq: "repeating/about/Alanna-01-desktop.png" }) {
            childImageSharp {
               gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
            }
         }
      }
   `);

   const backgroundImages = [
      getImage(data.backgroundDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.backgroundMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];

   const HeadingTag = headingLevel;

   return (
      <Background className={`${className || ""}`} backgroundImages={backgroundImages} backgroundPosition="65% 20%" padding="pt-10 md:py-36">
         <div className="grid md:grid-cols-2 md:gap-x-16 lg:gap-x-10 gap-y-16">
            <div>
               <HeadingTag className="text-white mb-0">Meet Alanna</HeadingTag>
               <p className="font-display text-mobile-5xl md:text-5xl text-white">Hi there!</p>
               <p className="text-mobile-lg md:text-lg text-white mb-5">
                  I’m attorney Alanna Pearl. Thanks for visiting the website for my law practice, Conscious Legal, APC. My boutique law firm educates
                  and empowers clients to make the best decisions throughout their life so they can experience peace, ease, and create a lasting
                  Legacy. You’ll find quickly I’m not your typical lawyer, and I think that’s a good thing. Please look around and if you like what
                  you see, send me a note to introduce yourself!
               </p>
               <ButtonUnderline href="/alanna-pearl/" text="Learn More" />
            </div>
            <div className="md:hidden">
               <GatsbyImage image={data.alanna.childImageSharp.gatsbyImageData} alt="Alanna Pearl" />
            </div>
         </div>
      </Background>
   );
};

export default About;
